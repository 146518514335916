import React, { useEffect, useRef } from "react";
import MemberShippingInformationFormStyles from "./MemberShippingInformationForm.styles";
import { Grid, MenuItem, Typography } from "@mui/material";
import Translate from "../../../../translate/Translate";
import PropTypes from "prop-types";
import { useFormFieldState, validateZipCode } from "../FormUtils";
import GLTextField from "../FormComponents/GLTextField";
import GLPlacesAutocomplete from "../FormComponents/GLPlacesAutocomplete";
import GLPlacesTextfield from "../FormComponents/GLPlacesAutocomplete/GLPlacesTextfield";
import { loadPlacesScript } from "../FormComponents/GLPlacesAutocomplete/placesApiUtils";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import GLButton from "../../sharedComponents/GLButton";
import { US_STATES } from "../../../../constants/generalConstants";
import GLSelectField from "../FormComponents/GLSelectField";

const MemberShippingInformationForm = (props) => {
  const { memberShippingData, onSubmit } = props;
  const classes = MemberShippingInformationFormStyles();
  const windowSize = useWindowSize();
  const usableHeight = windowSize.height - 205;
  const buttonPadding = usableHeight * 0.2;

  const initialState = {
    addressLine1: memberShippingData.addressLine1,
    addressLine2: memberShippingData.addressLine2,
    addressLine3: memberShippingData.addressLine3,
    city: memberShippingData.city,
    state: memberShippingData.state,
    zip: memberShippingData.postalCode,
    country: "United States of America"
  };

  const { formFields, formStatus, handleInputChange } = useFormFieldState(
    initialState
  );

  const loadedScript = useRef(false);

  useEffect(() => {
    if (!loadedScript.current) {
      loadPlacesScript();
      loadedScript.current = true;
    }
  }, [loadedScript]);

  const renderArrayAsMenuItems = (array) => {
    return array.map((item) => {
      return (
        <MenuItem key={item.id} name={item.id} value={item.value}>
          <Translate text={item.label} />
        </MenuItem>
      );
    });
  };

  const renderTextField = (
    id,
    label,
    value,
    isRequired,
    maxLength,
    additionalValidation
  ) => {
    return (
      <Grid container spacing={4}>
        <GLTextField
          id={id}
          label={label}
          value={value}
          required={isRequired}
          onChange={handleInputChange}
          inputProps={{ maxLength: maxLength }}
          additionalValidation={additionalValidation}
        />
      </Grid>
    );
  };
  return (
    <Grid container direction="row" justifyContent="center" spacing={2}>
      <Grid item xs={8} lg={6}>
        <Typography variant="h6" gutterBottom className={classes.sectionTitle}>
          <Translate text="Tell us where to send your test kit" />
        </Typography>
        <Grid container flexDirection={"column"}></Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Grid container spacing={4}>
              <GLPlacesAutocomplete
                id="addressLine1"
                label="Address 1"
                value={formFields.addressLine1}
                required
                onChange={handleInputChange}
                inputProps={{ maxLength: 255 }}
                fieldType="address"
                dependantFields={[
                  {
                    id: "zip",
                    parameter: "postal_code"
                  }
                ]}
                linkFieldOnTab="zip"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            {renderTextField(
              "addressLine2",
              "Address 2",
              formFields.addressLine2,
              false,
              255
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            {renderTextField(
              "addressLine3",
              "Address 3",
              formFields.addressLine3,
              false,
              255
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.containerSpacing}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              {renderTextField("city", "City", formFields.city, true, 255)}
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={4}>
                <GLSelectField
                  label={"State"}
                  id="state"
                  name="state"
                  value={formFields.state}
                  onChange={handleInputChange}
                  required
                >
                  {renderArrayAsMenuItems(US_STATES)}
                </GLSelectField>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={4}>
                <GLPlacesTextfield
                  id="zip"
                  label="Zip/Postal code"
                  value={formFields.zip}
                  required
                  onChange={handleInputChange}
                  additionalValidation={validateZipCode}
                  inputProps={{ maxLength: 24 }}
                  fieldType="address"
                  willItpredictByRegion
                  dependantFields={[
                    { id: "city", parameter: "locality" },
                    {
                      id: "state",
                      parameter: "administrative_area_level_1"
                    }
                  ]}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <GLTextField
                label="Country"
                id="country"
                value={formFields.country}
                onChange={handleInputChange}
                disabled={true}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            marginTop: buttonPadding,
            marginBottom: buttonPadding
          }}
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <GLButton
            loadingSpinnerWhenClicked
            id="submit-member-shipping-information"
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              const { zip, ...newValues } = formFields;
              onSubmit({ ...newValues, postalCode: formFields.zip });
            }}
            disabled={formStatus.invalidFields.length > 0}
          >
            <Translate text="Save" />
          </GLButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default MemberShippingInformationForm;

MemberShippingInformationForm.propTypes = {
  hasInfoBeenLoaded: PropTypes.bool,
  buttonPadding: PropTypes.number,
  memberShippingData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};
